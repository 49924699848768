#error-404-page {
  position: relative;
  height: 78vh;
}

#error-404-page .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#error-404-page .notfound {
  max-width: 800px;
  width: 100%;
  text-align: center;
  line-height: 1.4;
}

#error-404-page .notfound .notfound-404 h1 {
  color: #222;
  font-size: 220px;
  letter-spacing: 10px;
  margin: 0px;
  font-weight: 700;
  text-shadow: 2px 2px 0px #c9c9c9, -2px -2px 0px #c9c9c9;
}

#error-404-page .notfound .notfound-404 h1 > span {
  text-shadow: 2px 2px 0px #ffab00, -2px -2px 0px #ffab00, 0px 0px 8px #ffab00;
}

#error-404-page .text-404 {
  color: #fff;
  font-size: 2rem;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 15px;
}

.white-mode-site #error-404-page .text-404 {
  color: #000;
}

#error-404-page .notfound a {
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: transparent;
  color: #c9c9c9;
  border: 2px solid #c9c9c9;
  display: inline-block;
  padding: 10px 25px;
  font-weight: 700;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

@media screen and (max-width: 500px){
  #error-404-page .notfound .notfound-404 h1{
    font-size: 120px;
  }

  #error-404-page .text-404{
    font-size: 24px;
    padding: 15px;
  }
}