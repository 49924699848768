.white-mode-site #services-page .float-text{
  color:black;
}
.white-mode-site #services-page .cnt{
  border: 1px solid #d5d2d2;
}
.white-mode-site #services-page .grid-container-context{
  border: 1px solid #d5d2d2;
  background-color: black;
  color: white;
}
.white-mode-site #services-page .cnt{
  background-color: black;
  color: white;
}
.white-mode-site #services-page .swiper-slide {
  background-color: black;
  color: white;
  width: 100%;
  height: 375px;
  text-align: center;
  font-size: 18px;
  display: flex;
  align-items: center;
  border-radius: 25px;
}

.white-mode-site #services-page .text-overlay{
  color: white;
  margin-top: 10px;
}
#services-page .our-services-title {
  text-transform: uppercase;
  padding: 100px 0;
  font-size: 16px;
  letter-spacing: 9px;
  font-weight: bold;
}

#services-page .service-page-title {
  padding-bottom: 25px;
  text-transform: uppercase;
  padding-bottom: 1.15rem;
  font-size: 16px;
  letter-spacing: 9px;
  font-weight: bold;
  padding-bottom: 3rem;
}
#services-page .first-line {
  padding: 20vh 10vw 3vh 12vw;
  
}

#services-page .services-dark {
    color: black;
}
#services-page .description {
  align-items: center;
  font-size: 60px;
  padding-bottom: 50px;
}
#services-page #aliniere3 {
  padding-bottom: 50px;
}
#services-page .second-line {
  padding: 0 10vw;
  display: flex;
  justify-content:center;
  width: auto;
  overflow: hidden;
}
#services-page .cnt {
  width: 300px;
  height: 500px;
  background: white;
  margin-right:10px;
  position: relative;
  color: black;
  font-size: 2rem;
  text-align: center;
  padding: 50px 10px;
  border-radius: 25px;
}

#services-page .cnt-black {
  background: black;
  color: white;
}
#services-page .cnt:hover{
  background-color:grey; 
}

#services-page .overlay {
  height: 100%;
  width: 100%;
  transition: .7s ease;
}

#services-page .cnt:hover .overlay {
  padding-top: 10px;
  opacity: 1;
}

#services-page .text-overlay {
  color: black;
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  padding-top: 70px;
  width:71%;
}
#services-page .text-overlay-dark {
  color: white;
}

#services-page .services {
  text-align: center;
  width: auto;
  padding: 0 10vw
}
#services-page .grid-container {
  display: grid;
 /*  grid-template-columns: auto auto auto auto; */
 grid-template-columns: repeat(4, 310px);
 justify-content: center;
}
#services-page .grid-container-context {
  position: relative;
  display: flex;
  color: black;
  background-color: white;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  height: 250px;
  margin-left: 15px;
  margin-bottom: 20px;
  border-radius: 25px;
}

#services-page .middle {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .9s linear;
  background-color: #d5d2d2;
  border-radius: 25px;

}

#services-page .grid-container-context-dark {
  color: white;
  background-color: black;
}
#services-page .grid-container:hover > div:hover{
	background-color: grey;
}
#services-page .grid-container-context-dark:hover {
  background-color: grey;
}
#services-page .grid-container-context:hover .middle {
  opacity: 1;
}

#services-page .grid-container-text {
  color: black;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  width: 200px;
  white-space: pre-line;
}

#services-page #section-swiper {
  display: none;
}

#services-page #get-in-touch {
  text-transform: uppercase;
  padding-bottom: 3rem;
  font-size: 20px;
  letter-spacing: 9px;
}

#services-page .fix-contact {
  justify-content: center;
}

#services-page .contact {
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 2rem;
  cursor: pointer;
  flex-wrap: wrap;
}

#services-page .float-text {
  padding-right: 2vw;
  color: white;
  font-size: 24px;
  margin: 0;
}
#services-page #contact-us {
  margin: 100px 10vw;
  text-align: center;
}


#services-page #contact-us:hover .float-text {
  padding-right: 5vw;
  transition: padding 0.3s ease;
}
@media screen and (max-width: 1200px){
  #services-page .cnt-text{
    font-size: 32px;
    margin-bottom: 10px;
  }

  #services-page .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 300px);
    justify-content: center;
  }
  #services-page .grid-container-context {
    display: flex;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    height: 220px;
  }
}

@media screen and (max-width: 1100px){
  #services-page .first-line {
    padding: 18vh 10vh 0 12vh;
  }
  #services-page .ti-cursor.disabled{
    font-size: 44px;
  }
  
  #services-page .ti-cursor.with-delay{
    font-size: 44px;
  }
  #services-page .cnt {
    margin: 10px 0;
    height: 300px;
    position: relative;
    width: 650px;
    width: auto;
    min-width: 220px;
    font-size: 1.8rem;
  }

  #services-page .second-line {
    display: block;
    width: auto;
    overflow: hidden;
  }
  #services-page .text-overlay {
    display: block;
    color: black;
    transform: none;
    top: 100px;
    left: auto;
    bottom: auto;
    padding: 50px 15px;
    font-size: 16px;
    text-align: left;
    width: 100%;
  }
  #services-page .grid-container {
    display: none!important;
  } 
  #services-page #section-swiper {
    display: block!important;
    width: 100%;
    height: 375px;
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    color: black;
    border-radius: 25px;
  }


  #services-page .swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  #services-page .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
  #services-page .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  #services-page .swiper-slide-header {
    position: absolute;
    top: 25px;
  }
  #services-page #contact-us {
    margin: 100px 5vw;
    text-align: center;
  }

 
  #services-page .swiper-pagination {
    margin-top: 5px;
  }
  #services-page .float-text {
    font-size: 20px;
  }
  #services-page .contact-us:hover .float-text {
    padding-right: 2vw;
  }
  .desc-slide{
    max-width: 200px;
    padding-top: 10px;
  }
  .desc-slide:first-child{
    padding: 0;
  }
}
@media screen and (max-width: 700px){
  #services-page .cnt-text{
    font-size: 24px;
  }
  #services-page .description {
    font-size: 30px;
  }
  #services-page .ti-cursor.disabled{
    font-size: 30px;
  }
  
  #services-page .ti-cursor.with-delay{
    font-size: 30px;
  }
  #services-page .first-line {
    padding: 8vh 6vh;
  }
}