#describe-app {
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
}

#describe-app .hero-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 100px auto;
}

#describe-app .right-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
}

#describe-app .title-app {
  padding-bottom: 25px;
  text-transform: uppercase;
  padding-bottom: 1.15rem;
  font-size: 16px;
  letter-spacing: 9px;
  font-weight: bold;
  padding-bottom: 3rem;
}

#describe-app .short-description {
  font-size: 34px;
}

#describe-app .left-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  position: relative;
}

#describe-app .left-container img {
  width: 34%;
  position: absolute;
  top: -30px;
}

#describe-app .left-container .describeImg1 {
  right: 50%;
  z-index: 1;
}

#describe-app .left-container .describeImg1:hover {
  z-index: 3;
}

#describe-app .left-container .describeImg2 {
  left: 40%;
  z-index: 2;
}

#describe-app .description {
  line-height: 36px;
  padding-top: 10px;
  font-size: 20px;
}

#describe-app .description p {
  margin: 0;
  padding: 0;
}

#describe-app #get-in-touch {
  text-transform: uppercase;
  padding-bottom: 3rem;
  font-size: 20px;
  letter-spacing: 9px;
}

#describe-app .fix-contact {
  justify-content: center;
}

#describe-app .contact {
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 2rem;
  cursor: pointer;
  flex-wrap: wrap;
}

#describe-app .float-text {
  padding-right: 2vw;
  color: white;
  font-size: 24px;
  margin: 0;
}
#describe-app #contact-us {
  margin: 100px 10vw;
  text-align: center;
}

#describe-app #contact-us:hover .float-text {
  padding-right: 5vw;
  transition: padding 0.3s ease;
}

@media only screen and (max-width: 1200px) {
  #describe-app .hero-container {
    flex-direction: column;
  }

  #describe-app .left-container {
    width: 100%;
    margin: 20px 0;
  }

  #describe-app .left-container img {
    position: static;
    width: 25%;
  }

  #describe-app .hero-container {
    margin: 0;
    margin-top: 150px;
  }

  #describe-app .right-container {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  #describe-app .short-description {
    font-size: 24px;
  }
}
