#career-page #services1 {
    padding-bottom: 3rem;
    font-size: 22px;
    font-weight: 900;
}
#career-page .services {
    padding: 20vh 10vw 3vh 12vw;
}
#career-page .careers-text {
    position: relative;
    top: -80px;
    float: right;
    font-size: 20px;
}
#career-page .section-goTo-jobDescription{
    padding: 0 10vw;
    margin-bottom: 100px;
}
#career-page .careers_arrow-dark {
    filter: invert(100%);
}
#career-page .careers_a {
    color: unset;
}
#career-page .block_section{
    background-color: black;
    color: white;
    padding-top: 55px;
    padding-bottom: 55px;
    border-bottom: solid 1px #383635;
}
#career-page .block_section:hover {
    background-color: white;
    color: black;
    padding-left: 30px;
    padding-right: 30px;
    transition:  all ease .7s;
}
#career-page .block_section-dark {
    background-color: white;
    color: black;
    border-bottom: solid 1px #e5e5e5;
}
#career-page .block_section-dark:hover {
    background-color: black;
    color: white;
    padding-left: 30px;
    padding-right: 30px;
    transition:  all ease .7s;
} 
#career-page .block_text {
    font-size: 20px;
    padding-left: 50px;
}
#career-page .span_carrers {
    float: right;
    padding-right: 100px;
    font-size: 20px;
}

@media screen and (max-width: 1100px) {
    #career-page .careers-text {
        position: initial;
        font-size: 16px;
    }
    #career-page .carrers-text {
        position: initial;
    }
    #career-page .section-goTo-jobDescription{
        padding: 8vh 10vw 3vh 10vw;
        padding-bottom: 50px;
    }
    #career-page .careers_arrow {
        display: none;
    }
    #career-page .careers_arrow-dark{
        display: none;
    }
    #career-page .block_section {
        padding: 20px 0;
    }
    #career-page .block_section:hover {
        background-color: unset;
        color: unset;
    }
    #career-page .block_section-dark:hover {
        background-color: unset;
        color: unset;
    }
    #career-page .btnContainer {
        text-align: center;
        display: flex;
        flex-direction: column;
    }
    #career-page .block_text {
        text-align: center;
        padding: 0px;
        padding-top: 15px;
    }
    #career-page .span_carrers {
        float: initial;
        padding-right: 0px;
    }
}

@media only screen and (max-width: 1024px) {
    #career-page .carrers-text {
        position: inherit;
    }
}

@media screen and (max-width: 900px){
    #career-page .brCareers{
        display: none;
    }
    #career-page .carrers-text {
        position: initial;
    }
}

@media screen and (max-width: 425px) {
    #career-page .brCareers{
        display: block;
    }
    #career-page .services {
        padding: 8vh 6vh;
    }
}

@media screen and (max-width: 414px) {
    #career-page #services1 {
        padding-top: 0;
        font-size: 16px;
    }
}