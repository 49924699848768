.white-mode-site #job-apply-page .btn-apply {
  color: black !important;
  background-color: white !important;
}
.white-mode-site #job-apply-page .arrow2 {
  filter: invert(100%);
  padding-top: 18px;
}
#job-apply-page .arrow2 {
  padding-top: 18px;
}
#job-apply-page .message-area {
  height: 200px;
}
#job-apply-page .arrow {
  filter: invert(100%);
}

#job-apply-page .button-careers:hover .arrow2 {
  padding-left: 3vw;
  transition: padding 0.4s ease;
}

#job-apply-page .formApply {
  margin: 20vh 10vw 10vh 10vw;
  display: flex;
  flex-wrap: wrap;
}
#job-apply-page .textApply {
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 9px;
  font-size: 22px;
}
#job-apply-page .section-job-apply {
  margin: 0 10vw 100px 10vw;
  text-align: center;
}
.white-mode-site #job-apply-page input,
textarea {
  background-color: white;
}
#job-apply-page input:focus ~ label,
textarea:focus ~ label,
input:valid ~ label,
textarea:valid ~ label {
  font-size: 1.25rem;
  color: #ccc;
  top: -2.6rem;
  -webkit-transition: all 0.125s ease;
  transition: all 0.125s ease;
}
#job-apply-page .styled-input {
  float: left;
  width: 32%;
  margin: 2rem 0 1rem;
  position: relative;
}
#job-apply-page .wide1 {
  margin-right: 19px;
}
#job-apply-page .styled-input label {
  color: #999;
  padding: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  pointer-events: none;
}
#job-apply-page .styled-input.wide {
  width: 100%;
}

#job-apply-page input,
textarea {
  padding: 1rem 1rem;
  border: 0;
  width: 100%;
  font-size: 1rem;
  background-color: black;
  color: white;
  border-bottom: solid 1px #ccc;
}

#job-apply-page input ~ span,
textarea ~ span {
  display: block;
  width: 0;
  height: 2px;
  background: #2196f3;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

#job-apply-page textarea ~ span {
  bottom: 3px;
}

#job-apply-page input:focus,
textarea:focus {
  outline: 0;
}

#job-apply-page input:focus ~ span,
textarea:focus ~ span {
  width: 100%;
  -webkit-transition: all 0.075s ease;
  transition: all 0.075s ease;
}

#job-apply-page textarea {
  width: 100%;
  min-height: 12em;
}

#job-apply-page .btn-apply {
  padding: 15px 32px;
  font-size: 22px;
  align-items: center;
  margin-top: 15px;
}

#job-apply-page .btn-apply:hover {
  transition: padding 0.4s ease;
}

#job-apply-page .floatLeftBtn {
  float: left;
}
#job-apply-page .inputApply-dark {
  background-color: white;
  color: black;
}
#job-apply-page .br-taken-mobile {
  display: none;
}
#job-apply-page .br-taken-mobile2 {
  display: none;
}
#job-apply-page .uploadBg-dark {
  background-color: white;
  color: black;
}
#job-apply-page .error {
  color: red;
  float: left;
}
#job-apply-page .error-name {
  display: none;
}
#job-apply-page .error-valid-name {
  display: none;
}
#job-apply-page .error-email {
  display: none;
}
#job-apply-page .error-valid-email {
  display: none;
}
#job-apply-page .error-phone {
  display: none;
}
#job-apply-page .error-valid-phone {
  display: none;
}
#job-apply-page .error-message {
  display: none;
}
#job-apply-page .error-recaptcha {
  display: none;
}
#job-apply-page #thanks {
  color: green;
  float: left;
  display: none;
}

@media only screen and (max-width: 1200px) {
  #job-apply-page .wide1 {
    margin-right: 17px;
  }
}

@media screen and (max-width: 1100px) {
  #job-apply-page .textApply {
    font-size: 16px;
  }
  #job-apply-page .img_carrers {
    display: none;
  }
  #job-apply-page .formApply {
    margin: 20vh 10vw 5vh 10vw;
  }
  #job-apply-page .styled-input {
    width: 90%;
  }
  #job-apply-page .btn-apply {
    padding: 12px 24px;
    font-size: 16px;
    align-items: center;
  }
}

@media screen and (max-width: 320px) {
  #job-apply-page .white-mode-site .jobs-form input,
  .white-mode-site .jobs-form textarea {
    background-color: white;
    color: black;
  }
}
