#contactPage .contanerContact {
  padding: 4rem 0px 100px 0px;
}
#contactPage #h1-contact {
  text-align: left;
  margin-bottom: 120px;
  font-weight: bold;
  font-size: 32px;
}

#contactPage #h1-contact-section {
  text-align: left;
}

#contactPage #text-contact {
  text-transform: uppercase;
  padding-bottom: 1.15rem;
  font-size: 12px;
  letter-spacing: 9px;
  padding-left: 12vw;
  padding-bottom: 3rem;
}
#contactPage .contact-fix {
  text-transform: uppercase;
  padding-bottom: 1.15rem;
  font-size: 16px;
  letter-spacing: 7px;
  padding-left: 12vw;
  padding-bottom: 4rem;
}

#contactPage .styled-input1 {
  width: 100%;
  margin: 2rem 0 1rem;
  position: relative;
}
#contactPage .styled-input1 label {
  color: #999;
  padding: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  pointer-events: none;
}

#contactPage .uploadBg-dark {
    background-color: white;
    color: black;
}

#contactPage .styled-input1.wide { 
  width: 100%; 
}

#contactPage .harta-locatie {
  margin: 0 8vw;
} 

#contactPage .harta {
  padding-top: 20vh;
}

#contactPage .contanerContact {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 10vw;
}

#contactPage .contact-section {
  display: flex;
  flex-direction: column;
}

#contactPage h2#h2-contact-section{
  font-weight: bold;
  font-size: 32px;
}

#contactPage .contact-section h1 {
  text-align: center;
  color: white;
}

#contactPage .text-contact {
  font-size: 1.2rem;
  justify-content: center;
  padding: 5px;
  max-width: 650px;
}


#contactPage .contact-form{
  max-width: 600px;
  overflow: hidden;
 text-align: center;
}

#contactPage .contact-form-text{
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin: 16px 0;
  border: 0;
  padding: 20px 40px;
  outline: none;
  color: black;
  background-color: white;
  transition: 0.5s;
}
#contactPage .color-link {
  color: white;
  margin-left: 10px;
  vertical-align: middle;
  font-size: 24px;
}

.white-mode-site #contactPage .color-link {
  color: black;
}

.white-mode-site #contactPage .visit-us-link-icon{
  filter: invert(100%);
}

#contactPage textarea.contact-form-text{
  resize: none;
  height: 120px;
}

#contactPage .contact-form-btn{
  border: 0;
  background: #785511;
  color: #fff;
  padding: 12px 50px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  float: left;
}

#contactPage .contact-section-dark h1 {
  color: black;
}

#contactPage .contact-form-text-dark {
  color: white;
  background-color: black;
}
#contactPage .contact-form-btn-dark {
  color: white;
}

#contactPage .button-careers {
  text-align: center;
  padding: 100px 0px;
  display: flex; 
  align-items: center; 
  justify-content: center;
}

#contactPage .button-careers:hover button{
  padding-right: 3vw;
  transition: padding 0.4s ease;
}

#contactPage .button-text {
  display: inline-flex;
  align-items: center;
  font-size: 24px;
}

.white-mode-site #contactPage .arrow-right {
  filter: invert(100%);
}

#contactPage .wide1 {
  margin-right: 19px;
}

#contactPage input,
#contactPage textarea {
  padding: 1rem 1rem;
  border: 0;
  width: 100%;
  font-size: 1rem;
  background-color: inherit;
  color: white;
  border-bottom: solid 1px #ccc;
}

#contactPage textarea {
  min-height: 12em;
}

#contactPage input:focus{
  outline: 0;
}

#contactPage input:focus ~ label, 
#contactPage textarea:focus ~ label { 
  font-size: 1.25rem;
  color: #ccc;
  top: -2.6rem;
  transition: all 0.125s ease;
}

#contactPage input ~ span,
#contactPage textarea ~ span {
  display: block;
  width: 0;
  height: 2px;
  background: #2196F3;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.250s ease;
}

#contactPage textarea ~ span {
  bottom: 5px;
}

#contactPage input:focus ~ span,
#contactPage textarea:focus ~ span { 
  width: 100%;
  transition: all 0.25s ease;
}

#contactPage .btn-apply {
  padding: 15px 32px;
  font-size: 22px;
  align-items: center;
  margin-top: 15px;
  cursor: pointer; 
  border: none; 
  color: white;
  background-color: black;
}

#contactPage .btn-apply:hover{
  transition: padding 0.4s ease;
}

#contactPage .inputApply-dark {
  background-color: white;
  color: black;
}

/* end contact content */

@media screen and (max-width: 1100px) {
  #contactPage .color-link {
    font-size: 18px;
  }
  
  #contactPage .harta-locatie {
    margin: 0 12vw;
  }
  
  #contactPage .wide1 {
   margin-right: 17px;
  }

  #contactPage textarea {
    min-height: 10em;
  }
  
  #contactPage .contanerContact {
    display: block;
  }

  #contactPage #h1-contact {
    margin-bottom: 20px;
  }

  #contactPage .text-contact {
    font-size: 1rem;
    padding: 5px 0;
    text-align: left;
  }

  #contactPage .contact-section {
    padding-top: 50px;
  }

  #contactPage .container2 {
    display: flex;
    flex-direction: column;
  }

  #contactPage #harta {
    padding-top: 0;
    max-height: 350px;
    border:0;
  }

  #contactPage .arrow3{
    float: left;
    padding-right: 10px;
  }
 
  #contactPage .button-careers{
    border: none;
  }
  
  #contactPage .styled-input {
    width: 90%;
  }

  #contactPage .btn-apply {
    padding: 12px 24px;
    font-size: 18px;
    align-items: center
  }

}

@media screen and (max-width: 425px) {

  #contactPage .button-careers{
    padding: 25px 0px;
  }

  #contactPage .button-text {
    display: flex;
    flex-direction: column;
  }

  #contactPage .contanerContact {
    padding: 4rem 0 50px 0;
  }

}