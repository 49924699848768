.white-mode-site #homepage .text-arrow{
  color:black;
}

.white-mode-site #homepage .show-more-button{
  color:black;
}
.white-mode-site #homepage .container-clients{
  border: 1px solid rgba(0,0,0,.125);
}

#homepage #showcase {
  min-height: 505px;
  text-align: center;
  margin-top: 50px;
  padding-bottom: 10%;
  padding: 0 12vw;
}

#homepage #showcase h1 {
  padding-top: 15vh;
  font-size: 90px;
  padding-bottom: 50px;
  text-align: left;
  font-weight: 400;
  line-height: 1.1em;
}

#homepage #showcase p {
  font-size: 20px;
}

#homepage #showcase h4 {
  font-size: 20px;
}

#homepage #showcase2 h1 {
  padding: 5px 0;
  font-size: 1.7rem;
}

#homepage .get-in-touch{
  text-align: left;
  margin: 0 12vw 150px 12vw;
  justify-content: flex-start;
}

#homepage .hover-efect{
  display: grid;
}

#homepage #get-in-touch-title {
  text-transform: uppercase;
  padding-bottom: 3rem;
  font-size: 16px;
  letter-spacing: 9px;
  font-weight: bold;
  padding-bottom: 100px;
  text-align: left;
}

#homepage .contact {
  text-align: center;
  display: flex;
  font-size: 2rem;
  cursor: pointer;
  justify-content: center;
}

#homepage .contact:hover .text-arrow{
  padding-right: 3vw;
  transition: padding 0.4s ease;
}

#homepage .contact:hover #textFloatLeft{
  padding-right: 3vw;
  transition: padding 0.4s ease;
  
}

#homepage .companies{
  margin-top: 100px;
  margin-bottom: 100px;
}

#homepage .contact:hover .text-arrow {
  padding-right: 3vw;
  transition: padding 0.4s ease;
}

#homepage .contact a {
  display: flex;
}

#homepage .contact a img{
  height: 36px;
}

#homepage #textFloatLeft{
  padding-right: 2vw;
  font-size: 24px;
}

#homepage .row {
  margin: 0 auto;
  padding-top: 15px;
  max-width: 72rem;
  width: 100%;
  text-align: center;
}

#homepage .row-images::after {
  content: "";
  clear: both;
  display: table;
}

#homepage #row-fix {
  margin: 0 auto;
}

#homepage .apps {
  display: flex;
  justify-content: space-around;
  padding: 0 12vw;
  padding-bottom: 3rem;
}

#homepage .apps > p {
  padding: 0 15px;
  font-size: 2.5rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#homepage #typewriteID{
  font-size: 36px;
}

#homepage .apps > img {
  padding: 0 15px;
}

#homepage .br-taken-mobile2 {
  display: none;
}

#homepage .align {
  align-items: center;
  padding: 0 12vw;
  font-size: 50px;
  line-height: 1.5;
}

#homepage #ourApps {
  padding-bottom: 3rem;
}

#homepage #more {display: none;}

#homepage .show-more-button {
  vertical-align: middle;
  color: white;
  font-size: 16px;
  font-weight: bolder;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
  text-align: right;
  padding-right: 10px;
}

#homepage .show-more-button-dark {
  color:black;
  background-color: white;
  border-color: white;
  border-width: 0;
}

#homepage .img-show-more-button{
  vertical-align: middle;
  padding-left: 5px;
  cursor:pointer;
}


#homepage .maiMult {
  min-height: 100px;
  margin: 0 40vw;
  text-align: center;
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: center;
  align-items: center;
  cursor:pointer;
}

#homepage .maiMult1 {
  /*padding-top: 25px;*/
  min-height: 100px;
  margin: 0 40vw;
  text-align: center;
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: center;
  align-items: center;
}

#homepage .show-more {
  min-height: 100px;
  margin: 0 40vw;
  text-align: center;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#homepage .maiMult:hover #arrow{
  padding: 20px 0 0 5px;
  transition: padding ease .2s;
  -moz-transition: padding ease .2s;
  -o-transition: padding ease .2s;
  -webkit-transition: padding ease .2s;
  cursor:pointer;
}

#homepage .maiMult1:hover #arrow {
  padding: 0 0 20px 5px;
  transition: padding ease .2s;
  -moz-transition: padding ease .2s;
  -o-transition: padding ease .2s;
  -webkit-transition: padding ease .2s;
  cursor:pointer;
}

#homepage .show-more:hover #arrow{
  padding: 20px 0 0 5px;
  transition: padding ease .2s;
  -moz-transition: padding ease .2s;
  -o-transition: padding ease .2s;
  -webkit-transition: padding ease .2s;
  cursor:pointer;
}

#homepage #section-clients {
  margin-left: 30px;
  margin-right: 30px;
  text-align: center;
  padding-top: 25px;
}

#homepage #mobile-apps-swiper{
  display:none;
}

#homepage .darkmode {
  display: block;
}

#homepage .darkmode-none {
  display: none;
}

#homepage .block_bg {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

#homepage .lightmode{
  display: none;
}

#homepage .lightmode-block {
  display: block;
}

#homepage .client-item {
  position: relative;
  text-align: center;
}
  
#homepage .client-item img {
  padding-bottom: 0!important;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  padding: 0 5px;
  opacity: 0.6;
}
  
#homepage .client-item img:hover {
  opacity: 1;
  transition: opacity 1s ease;
}

#homepage .text-arrow {
  padding-right: 2vw;
  color: white;
  font-size: 24px;
  margin-bottom: 0;
}

#homepage .text-arrow-dark {
  color: black;
}

#homepage .container-all{
  margin: 5vh 10vw 0 10vw;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

#homepage .container-gallery{
  overflow:hidden;
  height: fit-content;
  margin:10px;
  padding: 0;
  display:block;
  position:relative;
  float:left;
  border-radius:25px;
}
#homepage .container-gallery:hover  img {
  transform: scale(1.2);
  transition-duration: .3s;
  filter: grayscale(10%);
  opacity: .7;
}
#homepage .container-gallery:hover span{
  color:white;
  display: block;
  transition-duration: .3s;
}

#homepage .colorText {
  background-color: none;
  text-align: center;
}

#homepage .img-gallery{
  width: 100%;
  transition-duration: .3s;
  max-width: 100%;
  display:block;
  overflow:hidden;
  cursor:pointer;
}

#homepage .title-work {
  position:absolute;
  display:block;
  display: none;
  cursor:pointer;
  top: 35%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -75%);
  font-size: 1.6em;
  font-weight: bold;
  transition-duration: .5s;
  color: grey;
}

#homepage .text-gallery {
  position:absolute;
  top: 70%;
  cursor:pointer;
  max-width: 80%;
  text-align:center;
  left: 50%;
  text-shadow: 1px 5px 10px black;
  font-size: 1em;
  display:none;
  margin-right: -50%;
  transition-duration: .3s;
  transform: translate(-50%, -50%) 
}
#homepage .container-clients{
  width: 83.33333%;
  margin-left: 8.33333%;
  padding-right: 10px;
  padding-left: 10px;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  background: white;
  border-radius: 25px;
}
#homepage .container-gif-gallery {
  margin: 0 10vw;
  display: block;
}
#homepage .important-text {
  text-transform: uppercase;
  padding-bottom: 50px;
  font-size: 16px;
  letter-spacing: 9px;
  font-weight: bold;
  text-align: center;
}

#homepage #important-text {
  padding-bottom: 3rem;
  text-align: left;
  padding-left: 12vw;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 9px;
}
#homepage .weGotMore {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  cursor: pointer;
}
#homepage .footer-home {
  margin: 0 10vw;
  text-align: center;
  padding: 100px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}
#homepage .footer-home-subtext {
  display: grid;
  text-align: center;
  justify-content: center;
}
#homepage #get-in-touch {
  text-transform: uppercase;
  padding-bottom: 3rem;
  font-size: 15px;
  letter-spacing: 9px;
  font-weight: bold;
}


@media screen and (max-width: 1100px) {
  #homepage .text-arrow {
    font-size: 20px;
  }
  #homepage .footer-home {
    display: block;
    margin: 0 10vw;
  }
  #homepage .footer-home-subtext {
    display: grid;
    text-align: center;
    justify-content: center;
    padding-bottom: 20px;
  }
  #homepage #get-in-touch {
    padding-bottom: 1.3rem;
  }
  #homepage .service-contact {
    text-align: center;
    padding: 0 2vw 5px;
    font-size: 22px;
  }
  #homepage .web-work{
    display: none;
  }
  #homepage #mobile-apps-swiper{
    display: block;
  }
  #homepage .important-text {
    padding-bottom: 100px;
  }
  #homepage .block_bg {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    z-index: -1;
  }
  #homepage .container-clients{
    width: 100%;
    margin-left: 0%;
    padding-right: 10px;
    padding-left: 10px;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
  #homepage .text-gallery {
    position: unset;
    display: block;
    transform: translate(0);
    max-width: 100%;
    text-shadow: none;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 15px;
  }
  #homepage #showcase {
    margin-top: 0;
    padding-bottom: 15px;
    min-height: 50vh;
  }
  #homepage #showcase h1 {
    padding-top: 18vh;
    padding-bottom: 18vh;
    font-size: 44px;
  }

  #homepage #showcase2 {
    padding: 0 12vw;
  }

  #homepage #showcase2 h1 {
    font-size: 2rem;
  }
  
  #homepage #showcase2 p {
    font-size: 16px;
  }

  #homepage .ia-legatura-cu-noi {
    margin: 0 12vw 70px 12vw;
  }

  #homepage #get-in-touch6 {
    text-align: center;
  }

  #homepage .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #homepage #important-text {
    padding: 40px;
    font-size: 12px;
    font-weight: bold;
    border-top: solid 1px #383635;
  }

  #homepage .apps {
    display: flex;
    flex-direction: column;
    padding: 10px 10vw;
    justify-content: center;
    align-items: center;
  }

  #homepage .apps > p {
    padding: 0;
  }

  #homepage .apps img {
    padding: 25px 0px;
  }
  #homepage .br-taken-mobile2 {
    display: block;
  }

  #homepage .align {
    font-size: 34px;
    padding: 0;
    line-height: normal;
    padding-left: 40px;
  }

 

  #homepage #ourApps{
    padding-bottom: 50px;
    padding-top: 50px;
  }

  #homepage .maiMult{
    display: none;
  }

  #homepage .maiMult1 {
    display: none;
  }

  #homepage .show-more {
    display: none;
  }

  #homepage #section-clients {
    margin-left: 30px;
    margin-right: 30px;
    text-align: center;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
  }

  #homepage .block_bg {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    z-index: -1;
  }

  #homepage .client-item {
    position: relative;
    text-align: center;
  }
      
  #homepage .client-item img {
    padding-bottom: 0!important;
    width: 100%;
    height: auto;
    max-width: 100%;
    position: relative;
    padding: 0 5px;
    opacity: 0.6;
  }
      
  #homepage .client-item img:hover {
    opacity: 1;
    transition: opacity 1s ease;
  }
  #homepage .container-all {
    grid-template-columns: 1fr;
    grid-gap:10px;
    margin: 0 10vw 0 10vw;
  }
  #homepage .container-gallery {
    margin: 20px;
  }
  #homepage .container-gallery:hover  img {
    transform: none;
    filter: none;
    opacity: 1;
  }
  #homepage .container-gallery:hover span{
    color:unset;
    display: block;
  }
  
}

@media only screen and (max-width: 1024px) {
  #homepage #showcase h1{
    padding-top: 19vh;
    padding-bottom: 19vh;
  }
  #homepage #get-in-touch-title {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 900px){
  #homepage #showcase h1 {
    padding-top: 25vh;
  }
  #homepage .get-in-touch{
    justify-content: center;
    margin: 0 12vw 50px 12vw
  }
  #homepage #get-in-touch-title { 
    text-align: center;
  }
  #homepage #hero .contact{
    border-bottom: solid 1px #383635;
  }

}

@media screen and (max-width: 425px) {
  #homepage #showcase h1{
    padding-top: 14vh;
    padding-bottom: 14vh;
  }

  #homepage .ia-legatura-cu-noi{
    margin: 0 12vw 70px 12vw;
  }

  #homepage #typewriteID{
    font-size: 36px;
  }
}

@media screen and (max-width: 414px) {
  #homepage .apps > p {
    font-size: 32px;
  }
  #homepage .important-text {
    padding-bottom: 50px;
    padding-top: 50px;
  }
  #homepage .footer-home {
    padding: 50px 0;
}
}

@media screen and (max-width: 375px) {
  #homepage #showcase h1{
    padding-top: 12vh;
    padding-bottom: 12vh;
  }

  #homepage #typewriteID{
    font-size: 32px;
  }
}


/**
*   NOTA: 
*
*   De aici in jos sunt stiluri copiate din 
*   tagul de style din body, a se reorganiza
*
*/

#homepage .swiper {
  max-width: 1600px;
  padding-bottom: 50px;
}

#homepage section#mobile-apps-swiper .swiper .swiper-slide{
  padding: 0 20px;
}

#homepage section#mobile-apps-swiper .swiper .swiper-slide img{
  box-shadow: 2px 2px 2px 2px #00000060;
  border-radius: 20px; 
}

#homepage #mobile-apps-swiper .swiper .swiper-pagination-bullet{
  border: 1px solid #fff;
}

#homepage #mobile-apps-swiper .swiper .swiper-pagination-bullet-active{
  border-color: #007aff;
}

#homepage .app-swiper {
  background-position: center;
  background-size: cover;
  width: 250px;
  height: 250px;
  border-radius: 10%;
}


#homepage .app-swiper img {
  display: block;
  max-width: 100%;
  height: 100%;
  border-radius: 10%;
    background-color: rgb(240, 234, 234);
  box-shadow: rgba(253, 253, 253, 0.1) 0px 20px 25px -5px,
    rgba(247, 247, 247, 0.04) 0px 10px 10px -5px;
}

@media screen and (min-width: 1500px) {
  #homepage .app-swiper {
    background-position: center;
    background-size: cover;
    width: 350px;
    height:350px;
  }
}

#homepage #mobile-apps-mobile{
  display: none;
}
@media screen and (max-width: 1500px){
  #homepage .swiper {
    max-width: 1000px!important;
  }
}
@media screen and (max-width: 1100px){
  #homepage #mobile-apps-mobile{
    display: block;
  }
  
  #homepage #mobile-apps-desktop{
    display: none;
  }
  #homepage #textFloatLeft{
    font-size: 22px;
  }
  #homepage .companies{
    margin-top: 20px;
  }
  #homepage .ti-cursor.disabled{
    font-size: 44px;
  }
  
  #homepage .ti-cursor.with-delay{
    font-size: 44px;
  }
}

@media screen and (min-width: 2000px) {
  #homepage .swiper {
    max-width: 1500px!important;
  }

  #homepage .app-swiper {
    background-position: center;
    background-size: cover;
    width: 300px;
    height:300px;

  }
}

@media screen and (max-width: 450px) {
  #homepage .swiper {
    max-width: 100%;
  }

  #homepage .app-swiper {
    background-position: center;
    background-size: cover;
    width: 200px;
    height: 200px;

  }
}