#privacy-policy {
  margin: 15vh 10vw 0 10vw;
}

#privacy-policy .cookie_header {
  text-align: center;
  padding-bottom: 25px;
}

#privacy-policy .cookie_p {
  padding: 0 100px;
  text-align: left; /* justify */
  text-indent: 50px;
  color: white;
}

.white-mode-site #privacy-policy .cookie_p {
  color: #000;
}

#privacy-policy .space-footer {
  padding-bottom: 100px;
}