#login-page .wrapper {
  width: 350px;
  padding: 20px;
  margin: 24vh auto;
}

#login-page .wrapper .form-group {
  margin-bottom: 25px;
}

#login-page .wrapper .form-control {
  margin-bottom: 5px;
}

#login-page .wrapper .form-group span {
  color: red;
}

#welcome-page {
  margin: 24vh auto;
  text-align: center;
}

#welcome-page .username::first-letter {
  text-transform: uppercase;
}

#welcome-page .buttons {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

#welcome-page .btn-see {
  background: #136f13;
  margin-right: 20px;
}

#database-page {
  padding: 100px 0;
}

#database-page h1 {
  text-align: center;
}

#database-page .overflow {
  width: 50%;
  overflow: overlay;
}

#database-page .page-header {
  margin-bottom: 20px;
}

#database-page .title-database {
  margin-bottom: 20px;
}

#database-page td {
  border-bottom: 2px solid #f0d691;
  border-left: 2px solid #d0a64d;
  border-right: 2px solid #f0d691;
  height: 100px;
}

#database-page thead {
  border-bottom: 2px solid #f0d691;
  border-left: 2px solid #d0a64d;
}

#database-page .table-wrapper {
  margin: 10px 70px 70px;
  box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.2);
}

#database-page .fl-table {
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  background-color: white;
}

#database-page .fl-table td,
#database-page .fl-table th {
  text-align: center;
  padding: 8px;
}

#database-page .fl-table td {
  border-right: 1px solid #f8f8f8;
  font-size: 12px;
  max-width: 300px;
  color: #000;
}

#database-page .fl-table thead th {
  background: #f0d691;
  color: #000;
}

#database-page .fl-table thead th:nth-child(odd) {
  background: #d0a64d;
  color: #000;
}

#database-page .fl-table tr:nth-child(even) {
  background: #f8f8f8;
}

@media (max-width: 767px) {
  #database-page .fl-table {
    display: block;
    width: 100%;
  }

  #database-page .table-wrapper:before {
    display: block;
    text-align: right;
    font-size: 11px;
    color: white;
    padding: 0 0 10px;
  }
  #database-page .fl-table thead,
  #database-page .fl-table tbody,
  #database-page .fl-table thead th {
    display: block;
  }

  #database-page .fl-table thead th:last-child {
    border-bottom: none;
  }

  #database-page .fl-table thead {
    float: left;
  }

  #database-page .fl-table tbody {
    width: auto;
    position: relative;
    overflow-x: auto;
  }

  #database-page .fl-table td,
  #database-page .fl-table th {
    padding: 20px 0.625em 0.625em 0.625em;
    height: 60px;
    vertical-align: middle;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    width: 120px;
    font-size: 13px;
    text-overflow: ellipsis;
  }

  #database-page .fl-table thead th {
    text-align: left;
    border-bottom: 1px solid #f7f7f9;
    border-right: 2px solid #f0d691;
  }

  #database-page .fl-table tbody tr {
    display: table-cell;
  }

  #database-page .fl-table tbody tr:nth-child(odd) {
    background: none;
  }

  #database-page .fl-table tr:nth-child(even) {
    background: transparent;
  }

  #database-page .fl-table tr td:nth-child(odd) {
    background: #f8f8f8;
    border-right: 1px solid #e6e4e4;
  }

  #database-page .fl-table tr td:nth-child(even) {
    border-right: 1px solid #e6e4e4;
  }

  #database-page .fl-table tbody td {
    display: block;
    text-align: center;
  }
}
