#article-page{
  margin-bottom: 150px;
}

body.white-mode-site #article-page #title{
  color: #000;
}

#article-page #title{
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 9px;
  font-weight: bold;
  color: #fff;
  margin-top: 150px;
} 

#article-page .article{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  margin: 0;
  max-width: 1000px;
  justify-content: center;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
  color:#000;
}

#article-page .titleBlog{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1000px;
  justify-content: flex-start;
  color: #000;
  align-content: center;
}

#article-page #articleImg{
  width: 100%;
  height: auto;
}

#article-page .text{
  color: #000;
  justify-items: baseline;
  padding: 70px;
}

#article-page .textTitle{
  font-family: AkkuratBold;
  font-size: 30px;
}

#article-page .category{
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: #818181;
  font-size: 15px;
  padding: 10px 0;
}

#article-page .brown{
  color: #D0A64D;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

#article-page .articleContent{
  line-height: 36px;
  padding-top: 10px;
  font-size: 20px;
}
#article-page .articleContent a{
  color: #0d6efd;
  text-decoration: underline;
}

#article-page h4{
  font-family: AkkuratBold;
  padding-top: 10px;
  font-size: 20px;
}

#article-page h3{
  font-family: AkkuratBold;
  text-transform: uppercase;
}

#article-page .share{
  padding-top: 50px;
  text-align: center;
}

#article-page .shareImg{
  padding: 10px;
}

#article-page .comments{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  margin: 20px 0;
  max-width: 1000px;
  justify-content: center;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
  padding-bottom: 90px;
}

#article-page .titleC{
  color: #000;
  border-bottom: 4px #000 solid;
  margin: 20px auto;
  font-size: 20px;
}

#article-page .name{
  font-family: Arial, sans-serif;
  color: #000;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

#article-page .comm{
  display: flex;
  padding: 50px;
  font-size: 25px;
  border-bottom: 1px dashed#BDC3C7;
}

#article-page .commText{
  font-size: 20px;
  color: #707070;
}

#article-page .commInside{
  padding-left: 30px;
}

#article-page .reply{
  text-transform: uppercase;
  font-size: 18px;
  color: #D0A64D;
  cursor: pointer;
}

#article-page .commReply{
  display: flex;
  font-size: 25px;
  padding-left: 15%;
}

#article-page .commLeaveTitle{
  padding: 50px;
  display: flex;
  margin: 0 auto; 
  text-align: center;
}

#article-page .commLeave{
  display: flex;
  width: 100%;
  justify-content: center;
}

#article-page .commLeave1{
  width: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#article-page .commLeave2{
  width: 50%;
  margin: 30px 50px 0 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#article-page .input{
  margin: 30px 50px 0 50px;
  font-family: Arial, sans-serif;
  width: 400px;
  height: 0;
  padding: 25px 20px;
  font-size: 20px;
  border: 1px solid #BDC3C7;
  background-color: #fff;
}

#article-page .commSubmit{
  margin: 30px 50px 0 50px;
  text-transform: uppercase;
  font-family: AkkuratBold;
  font-size: 20px;
  color: #fff;
  background-color: #D0A64D;
  padding: 25px 100px;
  padding-left: 16%;
  border-radius: 5%;
  border: 1px solid #BDC3C7;
}


#article-page .textarea{
  font-family: Arial, sans-serif;
  padding: 25px 20px;
  font-size: 20px;
  width: 100%;
  height: 100%;
  border: 1px solid #BDC3C7;
  background-color: white;
}

#article-page .none{
  display: none;
}

#article-page .imgProfile{
  width: 110px;
  height: 110px;
}

#article-page .footerArticle{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 80px;
  font-size: 20px;
}

#article-page ul br {
  display: none;
}

#article-page br{
  content: '';
  display: block;
  margin: 15px 0;
}

/* end article content */

@media screen and (max-width: 768px){

  #article-page #title{
    margin-top: 50px;
  }

  #article-page .commLeaveTitle{
    padding: 20px 70px;
  }

  #article-page .block{
    display: none;
  }

  #article-page .none{
    display: block;
  }

  #article-page .textarea{
    width: 400px;
  }

  #article-page .commLeave1{
    width: auto;
    justify-content: center;
  }

  #article-page .commLeave2{
    width: auto;
  }

  #article-page .commSubmit{
    width: 62%;
  }

  #article-page .textTitle{
    text-align: center;
  }

  #article-page .category{
    text-align: center;
  }

  #article-page .text{
    padding: 20px;
  }

  #article-page #category{
    text-align: center;
  }
  
  #article-page .footerArticle{
    justify-content: space-around;
    padding: 10px 40px;
    font-size: 15px;
  }

}

@media screen and (max-width: 600px){

  #article-page .category{
    padding: 10px;
    text-align: center;
    font-size: 20px;
  }

  #article-page .textTitle{
    font-size: 25px;
  }

  #article-page .article p {
    line-height: 26px;
    font-size: 15px;
  }

  #article-page .switch-button p{
    font-size: 12px;
  }

  #article-page .comm{
    padding: 15px;
  }

  #article-page h2{
    font-size: 15px!important;
  }

  #article-page .commText{
    font-size: 15px;
  }

  #article-page .block{
    display: none;
  }

  #article-page .none{
    display: block;
  }
  

  #article-page .input{
    width: auto;
  }

  #article-page .textarea{
    width: 290px;
  }

  #article-page .commSubmit{
    padding: 5%;
  }

  #article-page .name{
    flex-direction: column;
  }
  #article-page .imgProfile{
    width: 20%;
    height: 20%;
  }

  #article-page .commInside{
    padding-left: 15px;
  }

  #article-page .reply{
    font-size: 15px;
    margin-top: 1px;
    margin-bottom: 5px;
  }

  #article-page .name p{
    margin: 0;
  }

}

@media screen and (max-width: 400px){
  #article-page .commLeave1{
    width: 300px;
    justify-content: center;
  }
  #article-page .text {
    padding: 20px;
  }
  #article-page .category {
    padding: 0px;
    text-align: center;
    font-size: 15px;
  }
  #article-page .name p{
    font-size: 20px;
  }
  
}

@media screen and (max-width: 400px){

}