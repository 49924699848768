#app-dev-description-page .section-job-description {
    margin: 20vh 10vw 0 12vw;
}
#app-dev-description-page .col_2{
    display: grid;
    grid-template-columns: 1fr 1fr; 
    gap:10px 50px;
}
#app-dev-description-page .section-skills-required {
    margin: 100px 12vw 0 12vw;
}
#app-dev-description-page .ios-text {
    font-size: 24px;
    line-height: 1.2;
}
#app-dev-description-page .ios-text1 {
    font-size: 18px;
}

#app-dev-description-page .ios-dev-requiest {
    padding-top: 50px;
    font-size: 22px;
}
#app-dev-description-page .ul-ios {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    gap: 10px 50px;
    font-size: 16px;
}
#app-dev-description-page .section-goTo-jobApply {
    margin: 0 12vh;
}
#app-dev-description-page .section-goTo-jobApply-button {
    color: unset;
}
#app-dev-description-page .button-careers {
    text-align: center;
    padding: 100px 0px;
}
#app-dev-description-page .button-header{
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 9px;
    padding-bottom: 20px;
    color: #bababa;
    font-weight: 600;
}
#app-dev-description-page .button-text {
    display: inline-flex;
    align-items: center;
    font-size: 32px;
}
.white-mode-site #app-dev-description-page .button-text-img {
    filter: invert(100%);
}
#app-dev-description-page .button-text-img {
    padding-left: 20px;
}
#app-dev-description-page .section-goTo-jobApply:hover .button-text-img{
    padding-left: 40px;
    transition: ease .5s all;
}
#app-dev-description-page .button-text-img-dark {
    filter: invert(100%);
}
#app-dev-description-page .head-text-section-job-description {
    font-weight: 900;
    font-size: 24px;
}
#app-dev-description-page .section-skills-required-class {
    font-size: 19px;
    padding-left: 15px;
    font-weight: 900;
}
@media screen and (max-width: 1100px) {
    #app-dev-description-page .head-text-section-job-description {
        margin-top: 80px;
        font-size: 24px;
    }
    #app-dev-description-page .col_2{
        display: block;
    }
    #app-dev-description-page .ios-text {
        padding-bottom: 50px;
        font-size: 20px;
        line-height: 1.2;
    }
    #app-dev-description-page .section-skills-required {
        margin: 80px 12vw;
    }
    #app-dev-description-page .section-skills-required-class {
        font-size: 24px;
        padding-left: 15px;
    }
    #app-dev-description-page .ul-ios {
        display: block;
        padding-left: 10px;
        font-size: 16px;
    }
    #app-dev-description-page .button-careers{
       border: none;
    }
}

@media screen and (max-width: 900px){
    #app-dev-description-page .section-job-description {
        margin: 16vw 12vw;
    }
}

@media screen and (max-width: 425px) {
    #app-dev-description-page .section-skills-required{
        margin: 80px 10vw 30px 10vw;   
    }
    #app-dev-description-page .ios-dev-requiest{
        padding-top: 25px;
    }
    #app-dev-description-page .section-goTo-jobApply{
        margin: 50px 8vh;
    }
    #app-dev-description-page .button-careers{
        padding: 25px 0px;
    }
    #app-dev-description-page .button-header{
        font-size: 12px;
    }
    #app-dev-description-page .button-text {
        display: flex;
        flex-direction: column;
        font-size: 24px;
    }
    #app-dev-description-page .button-text-img {
        padding-left:0px;
    }
}

@media screen and (max-width: 320px) {
    #app-dev-description-page .section-skills-required{
        margin: 50px 12vw;
    }
    #app-dev-description-page .section-goTo-jobApply{
        margin: 50px 6vh 25px 6vh;
    }
}