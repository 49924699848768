#terms-and-conditions {
  margin: 15vh 10vw 0 10vw;
}

#terms-and-conditions .cookie_header {
  text-align: center;
  padding-bottom: 25px;
}

#terms-and-conditions .cookie_p {
  padding: 0 100px;
  text-align: left; /* justify */
  text-indent: 50px;
  color: white;
}

#terms-and-conditions .cookie_pdark {
  color: black;
}

#terms-and-conditions .space-footer {
  padding-bottom: 100px;
}

#terms-and-conditions #letters {
  padding: 0 20px;
}

#terms-and-conditions .li-tc-privacy{
  color: white;
}

#terms-and-conditions .li-tc-privacy-dark{
  color: black;
}

#terms-and-conditions .tcPadding{
  padding: 0 100px;
}