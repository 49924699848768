@font-face {
  font-family: myFont;
  src: url('../font/FontsFree-Net-Akkurat.ttf');
}

@font-face {
  font-family: 'Akkurat';
  src: url('../font/FontsFree-Net-Akkurat.ttf');
}
@font-face {
  font-family: 'AkkuratBold';
  src: url('../font/FontsFree-Net-Akkurat-Bold.ttf');
}
@font-face {
  font-family: 'Myriad Pro';
  src: url('../font/MyriadPro-It.otf');
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Akkurat';
  text-decoration: none;
}

a,
a[href] {
  color: inherit;
  text-decoration: none;
}

@keyframes slideInFromLeft {
  0% {
    top: 100%;
    opacity: 0;
  }
  50% {
    top: 100%;
    opacity: 0.3;
  }
  100% {
    top: 0%;
    opacity: 1;
  }
}

body {
  background: black;
  color: #dae0dc;
  animation: 2s linear slideInFromLeft;
}

body.white-mode-site {
  background-color: white;
  color: black;
}

.section-title {
  font-weight: bold;
}

.page-content + div {
  animation: 2s linear slideInFromLeft;
}

/* Progress bar */
._progress {
  --scroll: 0%;
  background: linear-gradient(to right, rgb(0, 143, 105) var(--scroll), transparent 0);
  background: linear-gradient(to right, white var(--scroll), transparent 0);
  position: fixed;
  width: 100%;
  height: 5px;
  top: 0px;
  z-index: 100;
}

.white-mode-site ._progress {
  background: linear-gradient(to right, black var(--scroll), transparent 0);
}
/* end of Progress bar */

nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  -webkit-align-items: center;
  min-height: 6vh;
  max-height: 10px;
}

.imgLogo {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  /*  padding-top: 170px; */
  transition: all 0.5s ease-in;
}

.logo {
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.imgLogo {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  /*  padding-top: 170px; */
  transition: all 0.5s ease-in;
}

.sticky1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.sticky {
  position: fixed;
  top: 5px;
  left: 5px;
  width: 90px;
  padding: 0;
  transition: width 0.5s ease;
}

.nav-links {
  display: flex;
  padding-top: 15px;
  width: 50%; /* 70vh; */
  justify-content: space-around;
  -webkit-justify-content: space-around;
  list-style: none;
}

.link-white {
  display: flex;
  width: 45%; /* 70vh; */
  /*  justify-content: space-around; */
  list-style: none;
}

.nav-links li {
  position: relative;
}

.nav-links a {
  /* color: #dae0dc; */
  text-decoration: none;
  letter-spacing: 2px;
}

.link-nav {
  color: white;
}

.link-nav1 {
  color: black;
}

.link-nav-dark {
  color: black;
}

.link-nav-white a {
  color: black;
  text-decoration: none;
  letter-spacing: 2px;
}

.link-nav:after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 3px;
  right: 0;
  background: white;
  transition: width 0.3s ease;
  -webkit-transition: width 0.3s ease;
}

.white-mode-site .link-nav:after {
  background: #000;
}

.link-nav1:after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 3px;
  right: 0;
  background: black;
  transition: width 0.3s ease;
  -webkit-transition: width 0.3s ease;
}

.nav-links li a:hover:after {
  width: 100%;
  left: 0;
}

/* select language */
.dropdown {
  float: left;
  overflow: hidden;
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.white-mode-site .dropbtn {
  color: black;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 50px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-left: -15px;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover {
  overflow: inherit;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.burger {
  display: none;
}

.centrate-slider .swiper-slide {
  justify-content: center;
}

.color-line {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 6px;
  transition: all 0.3s ease;
  box-shadow: 2px 2px #000;
}
body.white-mode-site .color-line {
  background-color: #000;
  box-shadow: none;
}

.color-line-dark {
  background-color: black;
}

#showcase {
  min-height: 505px;
  text-align: center;
  /* color: white; */
  margin-top: 50px;
  padding-bottom: 10%;
  padding: 0 12vw;
}

#shc2 {
  display: none;
}

/* switch button dark-light */
.checkbox {
  display: none;
}

.switch-button {
  position: fixed;
  top: 10vh;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}

.switch {
  align-items: center;
  background-color: gray;
  border-radius: 400px;
  cursor: pointer;
  display: flex;
  height: 25px;
  justify-content: space-between;
  padding: 0 10px;
  position: relative;
  user-select: none;
  width: 60px;
  transform: rotate(90deg);
}

.checkbox:checked ~ .switch {
  background-color: gray;
}

.checkbox:not(:checked) ~ .switch {
  background-color: gray;
}

.switch__left,
.switch__right {
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.checkbox:checked ~ .switch .switch__left {
  visibility: hidden;
}

.checkbox:not(:checked) ~ .switch .switch__right {
  visibility: hidden;
}

.switch__circle {
  height: 37px;
  padding: 5px;
  position: absolute;
  transition: all 0.1s linear;
  width: 37px;
}

.checkbox:checked ~ .switch .switch__circle {
  left: -3px;
  right: calc(100% - 33px);
}

.checkbox:not(:checked) ~ .switch .switch__circle {
  left: calc(100% - 33px);
  right: 0;
}

.switch__circle-inner {
  background-color: white;
  border-radius: 50%;
  display: block;
  height: 100%;
  width: 100%;
}
/* end of switch */

/* footer */
.page-footer {
  text-align: center;
  color: #dae0dc;
}

.white-mode-site .page-footer {
  color: black;
}

.alignleft {
  float: left;
}

#alignright {
  padding-right: 100px;
}

.alignright {
  float: right;
  padding-left: 10px;
  margin-bottom: 25px;
}

#footer_privacy_only {
  position: absolute;
  bottom: 0;
  left: 25%;
  right: 25%;
}

.social-media {
  padding-bottom: 25px;
}

.social-media i {
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
}

.social-media a {
  color: #dae0dc;
}

.fab-dark {
  color: #bababa;
}

.fa-facebook-f:hover {
  cursor: pointer;
  color: #3b5998;
}

.fa-instagram:hover {
  cursor: pointer;
  color: #833ab4;
}

.fa-linkedin:hover {
  cursor: pointer;
  color: #2867b2;
}

.footer-text {
  padding-bottom: 70px;
}

.footer-text a {
  color: unset;
}

#footer-text {
  float: left;
  padding-left: 100px;
}

.Important-text {
  text-transform: uppercase;
  padding-bottom: 100px;
  font-size: 16px;
  letter-spacing: 9px;
}
.Important-text1 {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 9px;
  padding-bottom: 3rem;
}
#important-text {
  padding-bottom: 3rem;
  text-align: left;
  padding-left: 12vw;
}
.aliniere2 {
  display: flex;
  align-items: center;
  font-size: 60px;
  padding-bottom: 200px;
}
#aliniere2 {
  padding-bottom: 50px;
}

@media screen and (max-width: 700px) {
  .nav-links {
    display: grid;
  }

  .footer-text {
    display: grid;
    grid-gap: 10px;
    justify-content: center;
    justify-items: center;
  }

  #footer-text {
    margin: 0 auto;
    padding-left: 0;
  }

  #alignright {
    padding-right: 0;
    padding-left: 0;
  }

  #alignTc {
    padding-left: 0;
  }

  .footer-text {
    padding-bottom: 50px;
  }

  .services {
    padding: 8vh 6vh;
  }
}
/* end of footer */

@media screen and (max-width: 1100px) {
  #important-text {
    font-size: 16px;
    padding-left: 0;
  }

  .Important-text {
    padding-bottom: 100px;
  }

  .imgLogo {
    width: 65px;
    padding-top: 2%;
  }

  body.white-mode-site .nav-links {
    background-color: #ffff;
  }

  .social-media {
    padding-bottom: 25px;
  }

  .social-media i {
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
  }

  .social-media a {
    color: #dae0dc;
  }

  body {
    overflow-x: hidden;
  }

  .imgLogo {
    width: 65px;
    padding-top: 2%;
  }

  nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 8vh;
    max-height: 10px;
  }

  .nav-links {
    position: fixed;
    right: 0px;
    top: 0px;
    height: 100%;
    background: black;
    display: grid;
    grid-template-rows: 50px 50px 50px 50px 50px;
    text-align: center;
    width: 100%;
    transform: translateX(100%);
    transition: transform 0.5s ease-in;
    z-index: 2;
  }

  body.white-mode-site .nav-links {
    background-color: #ffff;
  }

  .nav-links-dark {
    background: white;
  }

  .nav-links li {
    margin-top: 30vh;
  }

  .sticky {
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    opacity: 0.8;
  }

  .burger {
    z-index: 2;
    position: fixed;
    right: 20px;
    display: block;
    cursor: pointer;
    margin-left: auto;
    /*  margin-right: 10px; */
  }

  .toggle .line1 {
    transform: rotate(-45deg) translate(-3px, 3px);
  }

  .toggle .line3 {
    transform: rotate(45deg) translate(-3px, -3px);
  }

  .nav-active {
    transform: translateX(0%);
  }

  .color-line-active1,
  .color-line-active2,
  .color-line-active3 {
    background-color: white;
  }

  .color-line-active1-dark,
  .color-line-active3-dark {
    background-color: black;
  }

  .dropdown {
    padding: 0 10px;
  }

  .dropdown-content a {
    padding: 12px 26px;
  }

  .dropdown-content {
    margin-left: 0;
  }

  .burger {
    z-index: 2;
    position: fixed;
    display: block;
    cursor: pointer;
    margin-left: auto;
  }
}

@media screen and (max-width: 425px) {
  #aliniere2 {
    font-size: 42px;
  }
}

@media screen and (max-width: 414px) {
  .Important-text {
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .aliniere2 {
    font-size: 42px;
  }
}

@media screen and (max-width: 320px) {
  .toggle .line1 {
    transform: rotate(-45deg) translate(-3px, 3px);
  }

  .toggle .line3 {
    transform: rotate(45deg) translate(-3px, -3px);
  }
  #aliniere2 {
    font-size: 40px;
  }
}

/* Cookie consent */

.CookieConsent {
  padding: 0 10px;
  height: auto;
  align-items: center !important;
  justify-content: center !important;
}

.CookieConsent button {
  margin: 10px !important;
  background-color: #fff !important;
  color: grey !important;
  min-width: 110px !important;
  border-radius: 8px !important;
}

.CookieConsent button:hover {
  background-color: rgb(38, 38, 38) !important;
}

/* end Cookie consent */
