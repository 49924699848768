body.white-mode-site #blog-page .article {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

body.white-mode-site #blog-page #description {
  color: #000;
}

#blog-page .first-line {
  padding-bottom: 100px;
  padding-top: 130px;
}

#blog-page #title {
  text-transform: uppercase;
  padding-bottom: 100px;
  font-size: 16px;
  letter-spacing: 9px;
  padding-bottom: 3rem;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-family: AkkuratBold;
}

body.white-mode-site #blog-page #title {
  color: #000;
}

#blog-page .laptop {
  margin-top: 30px;
  width: 100%;
}

#blog-page #description {
  font-size: 50px;
}

#blog-page .article {
  color: #000;
  padding-left: 0;
  padding-bottom: 10px;
  height: auto;
  margin-right: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

#blog-page .article:last-child {
  margin-bottom: 150px;
}

#blog-page .imageArticle {
  width: 100%;
  height: auto;
}

#blog-page .imagePopular {
  width: 144px;
  height: auto;
}

#blog-page .article-content {
  padding-top: 24px;
  color: #000;
}

#blog-page .category {
  color: #818181;
  font-size: 14px;
  padding-bottom: 20px;
  text-align: start;
}

#blog-page .brown {
  color: #d0a64d;
}

#blog-page .titleArticle1 {
  font-size: 24px;
  font-family: AkkuratBold;
  text-align: center;
  padding: 0 80px;
}

#blog-page .articleText {
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  padding: 50px;
}

#blog-page #aside1 {
  background-color: #d0a64d;
  margin-bottom: 20px;
}

#blog-page #buttonS {
  width: 85%;
  color: #fff;
  background-color: #000;
  margin: 12px;
  font-family: AkkuratBold;
  border-radius: 0;
}

#blog-page .titleS {
  border-bottom: #fff solid;
  width: fit-content;
  margin: 0 auto;
}

#blog-page .textCard {
  font-family: 'Akkurat', Arial, Helvetica, sans-serif;
  font-size: 16px;
}

#blog-page .textS {
  width: 85%;
  padding: 5px;
  font-family: Lora;
  border-color: none;
}

#blog-page #aside2 {
  margin-bottom: 20px;
  color: #000;
}

#blog-page .titleP {
  width: fit-content;
  margin: 0 auto;
  border-bottom: #000 solid;
}

#blog-page .textP {
  font-size: 18px;
  font-family: AkkuratBold;
  margin: 0;
  color: #000;
}

#blog-page #aside3 {
  margin-bottom: 20px;
}

#blog-page .titleC {
  border-bottom: #000 solid;
  width: fit-content;
  margin: 0 auto;
  color: #000;
}

#blog-page .para {
  color: #000;
}

#blog-page .textC {
  color: #d0a64d;
  padding-top: 20px;
  font-family: 'Myriad Pro';
}

#blog-page .textStart {
  text-align: start;
}

#blog-page .anchor {
  color: inherit;
  text-decoration: none;
}

#blog-page #cardBlog {
  background-color: #d0a64d;
  border: 0;
}

#blog-page #cardBody {
  text-align: center;
}

#blog-page #cardInput {
  color: #acacac;
  background-color: #fff;
  outline: 0;
  font-family: 'Akkurat';
}

#blog-page #valid {
  color: rgb(24 70 0);
  margin: 0;
  display: none;
}

#blog-page #notValid {
  color: red;
  margin: 0;
  display: none;
}

#blog-page .marginMobile {
  width: fit-content;
}

@media screen and (max-width: 1200px) {
  #blog-page .articleText {
    padding: 25px;
  }

  #blog-page .center {
    justify-content: center;
  }

  #blog-page .marginMobile {
    margin: 0 auto;
  }

  #blog-page .textStart {
    text-align: center !important;
  }

  #blog-page .textP {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  #blog-page .category {
    text-align: center;
  }
}

/* end blog content */

@media screen and (max-width: 1000px) {
  #blog-page .article:last-child {
    margin-bottom: 20px;
  }

  #blog-page .article {
    margin-right: 0;
  }

  #blog-page #title {
    margin-top: 50px;
  }
  #blog-page .articleText {
    padding: 25px;
  }
  #blog-page .first-line {
    padding-top: 0;
  }
  #blog-page .mobil {
    display: flex !important;
    flex-wrap: wrap;
  }

  #blog-page .laptop {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto;
  }

  #blog-page .textP {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 600px) {
  #blog-page #description {
    font-size: 25px;
  }

  #blog-page .articleText {
    padding: 25px;
  }

  #blog-page .mobil {
    display: flex !important;
    flex-wrap: wrap;
  }

  #blog-page .laptop {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 147px;
  }

  #blog-page .marginMobile {
    margin: 0 auto;
  }

  #blog-page .textStart {
    text-align: center !important;
  }

  #blog-page .textP {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  #blog-page .titleArticle1 {
    font-size: inherit;
    padding: 0 10px;
  }

  #blog-page .category {
    padding: 10px;
    text-align: center;
  }

  body.white-mode-site #blog-page .article {
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin: 0;
    margin-bottom: 20px;
  }
}
