.white-mode-site #work-page {
  background-color: white;
  color: black;
}
.white-mode-site #work-page .our-work {
  color: black;
}
.white-mode-site #work-page .float-text {
  color: black;
}

#work-page {
  background: black;
  color: #dae0dc;
}

#work-page .title-page {
  padding-bottom: 3rem;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 9px;
  text-transform: uppercase;
}

#work-page .title-work {
  font-size: 26px;
  font-weight: bold;
  position: absolute;
  cursor: pointer;
  top: 35%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -75%);
  transition-duration: 0.5s;
  display: none;
}

#work-page .titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 25px;
}

#work-page .clickable {
  cursor: pointer;
}

#work-page #swiper-mobile {
  display: none;
}

#work-page .swiper-pagination {
  display: none;
}

#work-page .arrow {
  filter: invert(100%);
}

#work-page .our-work-dark {
  color: black;
}

#work-page .float-text {
  padding-right: 2vw;
  color: white;
  font-size: 24px;
  margin: 0;
}

#work-page .float-text-dark {
  color: black;
}

#work-page .contact-us {
  margin-left: 30px;
  margin-right: 30px;
  margin: 0 10vw;
  text-align: center;
  padding: 100px 0;
  display: grid;
}

#work-page .our-work {
  text-transform: uppercase;
  padding-bottom: 1rem;
  padding-top: 30px;
  font-size: 16px;
  letter-spacing: 9px;
  text-align: center;
  color: #dae0dc;
}

#work-page #get-in-touch2 {
  text-transform: uppercase;
  padding-top: 3rem;
  font-size: 16px;
  letter-spacing: 9px;
  text-align: center;
}

#work-page #contact-us-title {
  text-transform: uppercase;
  padding-bottom: 3rem;
  font-size: 12px;
  letter-spacing: 9px;
  font-weight: bold;
}

#work-page .contact {
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 2rem;
  cursor: pointer;
}
#work-page .fix-contact {
  justify-content: center;
}

#work-page .first-line {
  padding: 20vh 10vw 3vh 12vw;
}

#work-page .first-line-dark {
  color: black;
}
#services1 {
  padding-bottom: 3rem;
}
#work-page .description {
  align-items: center;
  font-size: 60px;
  line-height: 72px;
}

#work-page #description {
  padding-bottom: 50px;
}

#work-page .container-gif-gallery {
  margin: 0 10vw;
  display: block;
}
#work-page .container-web-apps {
  margin: 5vh 10vw 0 10vw;
  height: auto;
  /* width: fit-content; */
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(2, 1fr);
}

#work-page .container-gallery {
  overflow: hidden;
  height: fit-content;
  width: fit-content;
  margin: 10px;
  padding: 0;
  display: block;
  position: relative;
  float: left;
  border-radius: 25px;
}

#work-page #img-gallery {
  width: 100%;
  transition-duration: 0.3s;
  max-width: 1000px;
  display: block;
  overflow: hidden;
  cursor: pointer;
}
#work-page .img-gallery {
  width: 100%;
  transition-duration: 0.3s;
  max-width: 1000px;
  overflow: hidden;
  cursor: pointer;
}

#work-page .text-gallery {
  position: absolute;
  top: 70%;
  cursor: pointer;
  max-width: 80%;
  text-align: center;
  left: 50%;
  text-shadow: 1px 5px 10px black;
  font-size: 1em;
  display: none;
  margin-right: -50%;
  transition-duration: 0.3s;
  transform: translate(-50%, -50%);
}

#work-page .container-gallery:hover img {
  transform: scale(1.2);
  transition-duration: 0.3s;
  filter: grayscale(10%);
  opacity: 0.7;
}

#work-page .container-gallery:hover span {
  color: white;
  display: block;
  transition-duration: 0.3s;
}
#work-page .colorText {
  background-color: none;
  text-align: center;
}
#work-page .swiper-app {
  text-align: right;
  padding-right: 40px;
  display: flex;
  justify-content: flex-end;
}

#work-page .gif-text-left {
  justify-content: center;
  align-items: center;
  display: flex;
  padding-right: 30px;
}

#work-page .contact-us:hover .float-text {
  padding-right: 5vw;
  transition: padding 0.3s ease;
}
#work-page #swiper-container .swiper-slide {
  justify-content: center;
}

@media screen and (max-width: 1100px) {
  body {
    overflow-x: hidden;
  }

  #work-page .description {
    line-height: 62px;
  }

  #work-page .float-text {
    font-size: 22px;
    padding-right: 0;
  }

  #work-page #swiper-mobile {
    display: block;
    width: 100%;
  }
  #work-page .swiper-container {
    width: 100%;
    height: 100%;
  }
  #work-page .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    color: black;
    border-radius: 25px;
  }

  #work-page .swiper-slide-dark {
    color: white;
    background-color: black;
  }

  #work-page .contact {
    border-bottom: solid 1px #383635;
    display: flex;
    flex-direction: column;
  }

  #work-page .service-contact {
    font-size: 22px;
  }

  #work-page .colorText-dark {
    background-color: unset;
  }

  #work-page .container-gif-gallery {
    display: none;
  }

  #work-page .container-web-apps {
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin: 0 10vw 0 10vw;
  }
  #work-page .container-gallery {
    margin: 20px;
  }

  #work-page .text-gallery {
    position: unset;
    display: block;
    transform: translate(0);
    max-width: 100%;
    text-shadow: none;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 15px;
  }
  #work-page .contact-us {
    padding: 50px 0;
  }
  #work-page .contact-us:hover .float-text {
    padding-right: 2vw;
  }

  #work-page .container-gallery:hover img {
    transform: none;
    filter: none;
    opacity: 1;
  }
  #work-page .container-gallery:hover span {
    color: unset;
    display: block;
  }

  #work-page .service-contact {
    text-align: center;
    padding: 0 2vw 5px;
  }

  #work-page #get-in-touch2 {
    padding-top: 60px;
    padding-bottom: 50px;
    padding-left: 10px;
    padding-right: 10px;
  }
  #work-page .first-line {
    padding: 18vh 10vh 0 12vh;
  }
  #work-page .harta {
    padding-top: 8vh;
  }

  #work-page .title-work {
    position: initial;
    display: block;
    color: white;
    text-align: left;
    top: 0;
    left: 0;
    margin-right: 0;
    transform: translate(0, 0);
  }
  .white-mode-site #work-page .title-work {
    color: black;
  }
}

@media screen and (max-width: 600px) {
  #work-page .first-line {
    padding: 8vh 6vh;
  }

  #work-page #description {
    font-size: 48px;
  }

  #work-page .description {
    font-size: 42px;
  }

  #work-page #description {
    font-size: 40px;
  }
}

@media screen and (max-width: 500px) {
  #work-page .our-work {
    padding: 0 20px;
  }

  #work-page .description {
    line-height: 52px;
  }
}
